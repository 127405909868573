<template>
  <div class="container">
    <div class="legal-container">
      <h2 class="heading-secondary">Privacy Policy</h2>
      <h3 class="heading-tertiary">Introduction</h3>
      <p class="legal">
        Verdant Analysis Inc., also known as INVRS ("INVRS", "Company", "We",
        "US", or "Our") respect your privacy and are committed to protecting it
        by complying with this policy.
      </p>
      <p class="legal">This policy describes:</p>
      <ul>
        <li class="legal">
          How we collect, use, disclose, and protect the personal information of
          visitors and users of the INVRS website and mobile application
          ("you").
        </li>
        <li class="legal">
          Describes the types of information we may collect from you or that you
          may provide when you visit the website
          <a href="https://www.invrs.com/">https://www.invrs.com/</a>
          or use our mobile application (our "Platform").
        </li>
        <li class="legal">
          Our practices for collecting, using, maintaining, protecting, and
          disclosing that information.
        </li>
      </ul>
      <p class="legal">
        We will only use your personal information in accordance with this
        policy unless otherwise required by applicable law. We take steps to
        ensure that the personal information that we collect about you is
        adequate, relevant, not excessive, and used for limited purposes.
      </p>
      <p class="legal">
        We at Verdant Analysis Inc. have developed our privacy policy in
        compliance with the Canadian
        <a
          href="https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/"
          >Personal Information Protection and Electronic Documents Act</a
        >
        (PIPEDA) and the Substantially Similar Provincial Privacy Laws including
        Alberta's
        <a href="https://www.qp.alberta.ca/documents/Acts/P06P5.pdf"
          >Personal Information Protection Act</a
        >
        (Alberta PIPA), British Columbia's
        <a
          href="https://www.bclaws.gov.bc.ca/civix/document/id/complete/statreg/03063_01"
          >Personal Information Protection Act</a
        >
        (BC PIPA), and Quebec's Act
        <a href="legisquebec.gouv.qc.ca/en/document/cs/P-39.1"
          >Respecting the Protection of Personal Information in the Private
          Sector</a
        >
        (Quebec Act).
      </p>
      <p class="legal">
        Privacy laws in Canada generally define "personal information" as any
        information about an identifiable individual, which includes information
        that can be used on its own or with other information to identify,
        contact, or locate a single person.
      </p>
      <p class="legal">
        This policy applies to information we collect, use, or disclose about
        you:
      </p>
      <ul>
        <li class="legal">On the INVRS Platform.</li>
        <li class="legal">
          In email, text, and other electronic messages between you and the
          INVRS Platform.
        </li>
        <li class="legal">
          When you interact with our advertising and applications on third-party
          websites and services if those applications or advertising include
          links to this policy.
        </li>
      </ul>
      <p class="legal">
        The Platform may include links to third-party websites, plug-ins,
        services, social networks, or applications. Clicking on those links or
        enabling those connections may allow the third-party to collect or share
        data about you. If you follow a link to a third-party website or engage
        a third-party plugin, please note that these-third parties have their
        own privacy policies and we do not accept any responsibility or
        liability for these policies. We do not control these third-party
        websites, and we encourage you to read the privacy policy of every
        website you visit.
      </p>
      <p class="legal">This policy DOES NOT apply to information that:</p>
      <ul>
        <li class="legal">
          We collect offline or through any other means, including on any other
          Company or third-party website.
        </li>
        <li class="legal">
          You provide to or is collected by any third-party through any
          application or content (including advertising) that may link to or be
          accessible from or on the Platform.
        </li>
        <li class="legal">
          Links or content posted by other users or content creators.
        </li>
      </ul>
      <p class="legal">
        Please read this policy carefully to understand our policies and
        practices for collecting, processing, and storing your information. If
        you do not agree with our policies and practices, your choice is not to
        use our Platform. By accessing or using this Platform, you indicate that
        you understand, accept, and consent to the practices described in this
        policy. This policy may change from time to time (see Changes to Our
        Privacy Policy). Your continued use of this Platform after we make
        changes indicates that you accept and consent to those changes, so
        please check the policy periodically for updates.
      </p>
      <h3 class="heading-tertiary">1. Information We Collect About You</h3>
      <p class="legal">
        We collect and use several types of information from and about you when
        you visit or register an account to be a user of our Platform. We use
        this personal information for the business purpose of ensuring a better
        and more personalized user experience on the Platform.
      </p>
      <p class="legal">The type of information we collect includes:</p>
      <ul>
        <li class="legal">
          Personal information, that we can reasonably use to directly or
          indirectly identify you, such as your name, mailing address, e-mail
          address, telephone number, Internet protocol (IP) address used to
          connect your computer to the Internet, cookies, user name or other
          similar identifier, billing and account information, and any other
          identifier we may use to contact you ("personal information"). "). We
          may also ask you for your phone number as we sometimes use SMS or Text
          Verification to ensure your account and personal information is
          protected and only accessed by you. We provide an opportunity for any
          user to end their use of and delete their account from INVRS or
          opt-out of contact for marketing purposes, promotions, and/or contests
          on an ongoing basis by accessing our Platform, using the unsubscribe
          mechanism at the bottom of our e-mails, or by e-mailing to
          info@invrs.com.
        </li>
        <li class="legal">
          Non-personal information, that does not directly or indirectly reveal
          your identity or directly relate to an identifiable individual, such
          as demographic information, or statistical or aggregated information.
          Statistical or aggregated data does not directly identify a specific
          person, but we may derive non-personal statistical or aggregated data
          from personal information. For example, we may aggregate personal
          information to calculate the percentage of users accessing a specific
          Platform feature.
        </li>
        <li class="legal">
          Technical information, including your login information, browser type
          and version, time zone setting, browser plug-in types and versions,
          operating system and platform, or information about your internet
          connection, the equipment you use to access our Platform, and usage
          details.
        </li>
        <li class="legal">
          Non-personal details about your Platform interactions, including the
          full Uniform Resource Locators (URLs), clickstream to, through and
          from our Platform (including date and time), products you viewed or
          searched for, page response times, download errors, length of visits
          to certain pages, page interaction information (such as scrolling,
          clicks, and mouse-overs), methods used to browse away from the page,
          or any phone number used to call our customer service number.
        </li>
        <li class="legal">
          Other information, including background information such as your
          employment or education, trading information and preferences, and your
          subscriptions to other users and user generated content (UGC).
        </li>
      </ul>
      <h3 class="heading-tertiary">2. How We Collect Information About You</h3>
      <p class="legal">
        We use different methods to collect your information, including through:
      </p>
      <ul>
        <li class="legal">
          Direct interactions with you when you provide it to us, for example,
          by filling in forms, setting up your account, or corresponding with us
          by phone, email, or otherwise.
        </li>
        <li class="legal">
          Public Posting and User Generated Content. You may also provide
          information for us to publish or display on public Platform areas or
          transmit to other Platform users or third-parties.
        </li>
        <li class="legal">
          Automated technologies or interactions, as you navigate through our
          Platform. Information collected automatically may include usage
          details, IP addresses, and information collected through cookies, web
          beacons, and other tracking technologies.
        </li>
        <li class="legal">
          Third-Parties or publicly available sources, for example platforms and
          services such as Google, Facebook, Twitter, Instagram, TikTok,
          LinkedIn, and our business partners such as Hubspot and MixPanel.
        </li>
      </ul>
      <p class="legal"><strong>Information You Provide to Us </strong></p>
      <p class="legal">
        The information we collect directly from you on or through our Platform
        may include:
      </p>
      <ul>
        <li class="legal">
          Information that you provide by filling in forms on our Platform. This
          includes information provided at the time of registering an account to
          use our Platform, subscribing to our service, posting material and any
          UGC. We may also ask you for information when you enter a contest or
          promotion sponsored by us, and when you report a problem with our
          Platform.
        </li>
        <li class="legal">
          Records and copies of your correspondence (including email addresses
          or username), if you contact us.
        </li>
        <li class="legal">
          Your responses to surveys that we might ask you to complete for
          research purposes.
        </li>
        <li class="legal">
          Details of transactions you carry out through our Platform and of the
          fulfillment of your orders.
        </li>
        <li class="legal">Your search queries on the Platform.</li>
      </ul>
      <p class="legal">
        You may also provide information to be published or displayed
        (hereinafter, "posted") on public areas of the Platform or transmitted
        to other users of the Platform or third-parties (collectively, "User
        Generated Content" or "UGC"). Your UGC are posted on and transmitted to
        others at your own risk. Although you may set certain privacy settings
        for such information by logging into your account profile, please be
        aware that no security measures are perfect. Additionally, we cannot
        control the actions of other users of the Platform with whom you may
        choose to share your UGC. Therefore, we cannot and do not guarantee that
        unauthorized persons will not view your UGC.
      </p>
      <p class="legal">
        <strong
          >Information We Collect Through Cookies and Other Automatic Data
          Collection Technologies</strong
        >
      </p>
      <p class="legal">
        As you navigate through and interact with our Platform, we may use
        cookies or other automatic data collection technologies to collect
        certain information about your devices, browsing actions, and patterns,
        including:
      </p>
      <ul>
        <li class="legal">
          Details of your access to the Platform, including traffic data,
          location data, logs, and other communication data and the resources
          that you access and use on the Platform.
        </li>
        <li class="legal">
          Information about your computer, laptop, mobile device and internet
          connection, including your IP address, operating system, browser type,
          mobile network information, and the mobile device's telephone number.
        </li>
        <li class="legal">
          The mobile application in particular may access metadata and other
          information associated with other files stored on your device. This
          may include, for example, images, audio and video clips, personal
          contacts, and address book information.
        </li>
      </ul>
      <p class="legal">
        We may also use these technologies to collect information about your
        online activities over time and across third-party websites or other
        online services (behavioral tracking). To learn more or to opt-out of
        tailored advertising please visit Digital Advertising Alliance of Canada
        Opt-Out Tool for information on how you can opt out of behavioral
        tracking on this Platform and how we respond to web browser signals and
        other mechanisms that enable consumers to exercise choice about
        behavioral tracking.
      </p>
      <p class="legal">
        The information we collect automatically is statistical information and
        may include personal information, and we may maintain it or associate it
        with personal information we collect in other ways, that you provide to
        us, or receive from third-parties. It helps us to improve our Platform
        and to deliver a better and more personalized user experience, including
        by enabling us to:
      </p>
      <ul>
        <li class="legal">Estimate our user size and usage patterns.</li>
        <li class="legal">
          Store information about your preferences, allowing us to customize our
          Platform, including Social Feeds, Group and Creator suggestions,
          according to your individual interests.
        </li>
        <li class="legal">Speed up your searches.</li>
        <li class="legal">Recognize you when you return to our Platform.</li>
      </ul>
      <p class="legal">
        The technologies we use for this automatic data collection may include:
      </p>
      <ul>
        <li class="legal">
          Cookies (or browser cookies). A cookie is a small file placed on the
          hard drive of your computer. You may refuse to accept browser cookies
          by activating the appropriate setting on your browser. However, if you
          select this setting you may be unable to access certain parts of our
          Platform. Unless you have adjusted your browser setting so that it
          will refuse cookies, our system will issue cookies when you direct
          your browser to our Platform.
        </li>
        <li class="legal">
          Flash Cookies. Certain features of our Platform may use local stored
          objects (or Flash cookies) to collect and store information about your
          preferences and navigation to, from, and on our Platform. Flash
          cookies are not managed by the same browser settings that are used for
          browser cookies. For information about managing your privacy and
          security settings for Flash cookies, see Choices About How We Use and
          Disclose Your Information.
        </li>
        <li class="legal">
          Web Beacons. Pages of our Platform and our e-mails may contain small
          electronic files known as web beacons (also referred to as clear gifs,
          pixel tags, and single-pixel gifs) that permit the Company, for
          example, to count users who have visited those pages, and for other
          related website statistics (for example, recording the popularity of
          certain website content and verifying system and server integrity).
        </li>
      </ul>
      <p class="legal">
        <strong
          >Third-Party Use of Cookies and Other Tracking Technologies</strong
        >
      </p>
      <p class="legal">
        Some content or applications on the Platform, including advertisements
        or link to features and services, are served by third-parties such as
        Google, Facebook, Twitter, Instagram, TikTok, LinkedIn, or advertisers,
        ad networks and servers, content providers, and application providers.
        These third-parties may use cookies to collect information about you
        when you use our Platform. The information they collect may be
        associated with your personal information or they may collect
        information, including personal information, about your online
        activities over time and across different websites and other online
        services. They may use this information to provide you with
        interest-based (behavioural) advertising or other targeted content.
      </p>
      <p class="legal">
        In addition to helping advertisers reach the right people for their
        products and services, behavioural advertising helps support our
        Platform so that you can enjoy free content.
      </p>
      <p class="legal">
        You can opt-out of several third-party ad servers' and networks' cookies
        simultaneously by using an opt-out tool created by the
        <a href="https://youradchoices.ca/en/tools"
          >Digital Advertising Alliance of Canada</a
        >
        or an opt-out tool created by the
        <a href="https://optout.networkadvertising.org/?c=1"
          >Network Advertising Initiative</a
        >. You can also access these websites to learn more about online
        behavioural advertising and how to stop websites from placing cookies on
        your device. Opting out of a network does not mean you will no longer
        receive online advertising. It does mean that the network from which you
        opted out will no longer deliver ads tailored to your web preferences
        and usage patterns.
      </p>
      <p class="legal">
        We do not control these third-parties' tracking technologies or how they
        are used. If you have any questions about an advertisement or other
        targeted content, you should contact the responsible provider directly.
        For more information about how you can opt out of receiving targeted
        advertising from many providers, see Choices About How We Use and
        Disclose Your Information.
      </p>
      <h3 class="heading-tertiary">3. How We use Your Information</h3>
      <p class="legal">
        We use information that we collect about you or that you provide to us,
        including any personal information:
      </p>
      <ul>
        <li class="legal">
          To present the INVRS Platform and its contents to you.
        </li>
        <li class="legal">
          To provide you with information, products, or services that you
          request from us.
        </li>
        <li class="legal">
          To fulfill the purposes for which you provided the information or that
          were described when it was collected, or any other purpose for which
          you provide it.
        </li>
        <li class="legal">
          To provide you with notices about your account, including expiration
          and renewal notices.
        </li>
        <li class="legal">
          To carry out our obligations and enforce our rights arising from any
          contracts with you, including for billing and collection or to comply
          with legal requirements.
        </li>
        <li class="legal">
          To notify you about changes to our Platform or any products or
          services we offer or provide though it.
        </li>
        <li class="legal">
          To improve our Platform, products or services, marketing, or customer
          relationships and experiences.
        </li>
        <li class="legal">
          To allow you to participate in interactive features, social media, or
          similar features on our Platform.
        </li>
        <li class="legal">
          To measure or understand the effectiveness of the advertising we serve
          to you and others, and to deliver relevant advertising to you.
        </li>
        <li class="legal">
          In any other way we may describe when you provide the information.
        </li>
        <li class="legal">For any other purpose with your consent.</li>
      </ul>
      <p class="legal">
        We may use the information we have collected from you to enable us to
        display advertisements to our advertisers' target audiences. Even though
        we do not disclose your personal information for these purposes without
        your consent, if you click on or otherwise interact with an
        advertisement, the advertiser may assume that you meet its target
        criteria.
      </p>
      <h3 class="heading-tertiary">4. Disclosure of Your Information</h3>
      <p class="legal">
        We may disclose personal information that we collect or you provide as
        described in this privacy policy:
      </p>
      <ul>
        <li class="legal">
          In accordance with applicable law, to a buyer or other successor in
          the event of a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of Verdant
          Analysis Inc.'s assets, whether as a going concern or as part of
          bankruptcy, liquidation, or similar proceeding, in which personal
          information held by Verdant Analysis Inc. about our customers and
          users is among the assets transferred.
        </li>
        <li class="legal">
          To advertisers and advertising networks that require the information
          to select and serve relevant advertisements to you and others. We do
          not disclose data about identifiable individuals to our advertisers,
          but we may provide them with aggregate information about our users
          (for example, we may inform them that 400 women between 30 and 45 have
          clicked on their advertisement on a specific day). We may also use
          such aggregate information to help advertisers target a specific
          audience (for example, men in a specific location). We may make use of
          the personal information we have collected from you to enable us to
          display our advertisers' advertisement to that target audience.
        </li>
        <li class="legal">
          To third-parties to market their products or services to you if you
          have not opted out of these disclosures. For more information, see
          Choices About How We Use and Disclose Your Information.
        </li>
        <li class="legal">
          To contractors, service providers, and other third-parties we use to
          support our business (such as analytics and search engine providers
          that assist us with Platform improvement and optimization) and who are
          contractually obligated to keep personal information confidential, use
          it only for the purposes for which we disclose it to them, and to
          process the personal information with the same standards set out in
          this policy.
        </li>
        <li class="legal">To fulfill the purpose for which you provide it.</li>
        <li class="legal">
          For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li class="legal">With your consent.</li>
      </ul>
      <p class="legal">We may also disclose your personal information:</p>
      <ul>
        <li class="legal">
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request, in accordance with
          applicable law.
        </li>
        <li class="legal">
          To enforce or apply our <a href="terms.html">terms of use</a> and
          other agreements, including for billing and collection purposes.
        </li>
        <li class="legal">
          If we believe disclosure is necessary or appropriate to protect the
          rights, property, or safety of Verdant Analysis Inc., our customers,
          or others.
        </li>
      </ul>
      <h3 class="heading-tertiary">
        5. Transferring Your Personal Information
      </h3>
      <p class="legal">
        We may transfer personal information that we collect or that you provide
        as described in this policy to contractors, service providers, and other
        third-parties we use to support our business (such as analytics and
        search engine providers that assist us with Platform improvement and
        optimization) and who are contractually obligated to keep personal
        information confidential, use it only for the purposes for which we
        disclose it to them, and to process the personal information with the
        same standards set out in this policy.
      </p>
      <p class="legal">
        We may process, store, and transfer your personal information in and to
        a foreign country, with different privacy laws that may or may not be as
        comprehensive as Canadian law. In these circumstances, the governments,
        courts, law enforcement, or regulatory agencies of that country may be
        able to obtain access to your personal information through the laws of
        the foreign country.
      </p>
      <p class="legal">
        By submitting your personal information or engaging with the Platform,
        you consent to this transfer, storage, or processing.
      </p>
      <h3 class="heading-tertiary">
        6. Choices About How We Use and Disclose Your Information
      </h3>
      <p class="legal">
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:
      </p>
      <ul>
        <li class="legal">
          Tracking Technologies and Advertising. You can set your browser to
          refuse all or some browser cookies, or to alert you when cookies are
          being sent. If you disable or refuse cookies, please note that some
          parts of this Platform may not be accessible or may not function
          properly. For more information about tracking technologies, see
          Information We Collect Through Cookies and Other Automatic Data
          Collection Technologies.
        </li>
        <li class="legal">
          Third-Party Advertising. If you do not want us to share your personal
          information with unaffiliated or non-agent third-parties for
          promotional purposes, you can opt-out by logging into the Platform and
          adjusting your user preferences in your account profile by checking or
          unchecking the relevant boxes or by sending us an email stating your
          request to info@invrs.com.
        </li>
        <li class="legal">
          Promotional Offers from INVRS. If you have opted in to receive certain
          emails from us but no longer wish to have your email address or
          contact information used by the INVRS to promote our own or
          third-parties' products or services, you can opt-out by logging into
          the Platform and adjusting your user preferences in your account
          profile by checking or unchecking the relevant boxes or by sending us
          an email stating your request to info@invrs.com. If we have sent you a
          promotional email, you may unsubscribe by clicking the unsubscribe
          link we have included in the email.
        </li>
        <li class="legal">
          Targeted Advertising. If you do not want us to use information that we
          collect or that you provide to us to deliver advertisements according
          to our advertisers' target-audience preferences, you can opt out by
          logging into the Platform and adjusting your user preferences in your
          account profile by checking or unchecking the relevant boxes or by
          sending us an email stating your request to info@invrs.com. We do not
          control third-parties' collection or use of your information to serve
          interest-based advertising. However, these third-parties may provide
          you with ways to choose not to have your information collected or used
          in this way. You can opt out of several third-party ad servers' and
          networks' cookies simultaneously by using an opt-out tool created by
          the
          <a href="https://youradchoices.ca/en/tools"
            >Digital Advertising Alliance of Canada</a
          >
          or an opt-out tool created by the
          <a href="https://optout.networkadvertising.org/?c=1"
            >Network Advertising Initiative</a
          >. Opting out of a network does not mean you will no longer receive
          online advertising. It does mean that the network from which you opted
          out will no longer deliver ads tailored to your web preferences and
          usage patterns.
        </li>
      </ul>
      <h3 class="heading-tertiary">7. Data Security</h3>
      <p class="legal">
        The security of your personal information is very important to us. We
        use physical, electronic, and administrative measures designed to secure
        your personal information from accidental loss and from unauthorized
        access, use, alteration, and disclosure. We store all information you
        provide to us behind firewalls on our secure servers. Any payment
        transactions will be encrypted using SSL technology.
      </p>
      <p class="legal">
        The safety and security of your information also depends on you. Where
        we have given you (or where you have chosen) a password for access to
        certain parts of our Platform, you are responsible for keeping this
        password confidential. We ask you not to share your password with
        anyone. We urge you to be careful about giving out information in public
        areas of the Platform like message boards, on postings, or in Private or
        Public Groups, which another Platform visitor or user can view.
      </p>
      <p class="legal">
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Platform. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Platform.
      </p>
      <h3 class="heading-tertiary">8. Data Retention</h3>
      <p class="legal">
        Except as otherwise permitted or required by applicable law or
        regulation, we will only retain your personal information for as long as
        necessary to fulfill the purposes we collected it for, including for the
        purposes of satisfying any legal, accounting, or reporting requirements.
        Under some circumstances we may anonymize your personal information so
        that it can no longer be associated with you. We reserve the right to
        use such anonymous and de-identified data for any legitimate business
        purpose without further notice to you or your consent.
      </p>
      <h3 class="heading-tertiary">9. Children Under the Age of 13</h3>
      <p class="legal">
        Our Platform is not intended for children under 13 years of age. No one
        under age 13 may provide any information to or on the Platform. We do
        not knowingly collect personal information from children under 13. If
        you are under 13, do not use or provide any information on this Platform
        or on or through any of its features, register an account, use any of
        the interactive or public comment features of this Platform, or provide
        any information about yourself to us, including your name, address,
        telephone number, email address, or any screen name or user name you may
        use. If we learn we have collected or received personal information from
        a child under 13 without verification of parental consent, we will
        delete that information. If you believe we might have any information
        from or about a child under 13, please contact us at info@invrs.com. We
        will not be responsible or held liable for any data deleted or removed
        of any user found to be under 13 years of age.
      </p>
      <h3 class="heading-tertiary">
        10. Accessing and Correcting Your Personal Information
      </h3>
      <p class="legal">
        It is important that the personal information we hold about you is
        accurate and current. Please keep us informed if your personal
        information changes. By law you have the right to request access to and
        to correct the personal information that we hold about you.
      </p>
      <p class="legal">
        You can review and change your personal information by logging into the
        Platform and visiting your account profile page.
      </p>
      <p class="legal">
        If you want to review, verify, correct, or withdraw consent to the use
        of your personal information you may also send us an email at
        info@invrs.com to request access to, correct, or delete any personal
        information that you have provided to us.
      </p>
      <p class="legal">
        We may request specific information from you to help us confirm your
        identity and your right to access, and to provide you with the personal
        information that we hold about you or make your requested changes.
        Applicable law may allow or require us to refuse to provide you with
        access to some or all of the personal information that we hold about
        you, or we may have destroyed, erased, or made your personal information
        anonymous in accordance with our record retention obligations and
        practices. If we cannot provide you with access to your personal
        information, we will inform you of the reasons why, subject to any legal
        or regulatory restrictions.
      </p>
      <p class="legal">
        We will provide access to your personal information, subject to
        exceptions set out in applicable privacy legislation.
      </p>
      <p class="legal">
        If you are concerned about our response or would like to correct the
        information provided, you may contact our Privacy Officer at
        info@invrs.com.
      </p>
      <p class="legal">
        If you delete your UGC from the Platform, copies of your UGC may remain
        viewable in cached and archived pages or might have been copied or
        stored by other Platform users.
      </p>
      <h3 class="heading-tertiary">11. Withdrawing Your Consent</h3>
      <p class="legal">
        Where you have provided your consent to the collection, use, and
        transfer of your personal information, you may have the legal right to
        withdraw your consent under certain circumstances. To withdraw your
        consent, if applicable, contact us at info@invrs.com. Please note that
        if you withdraw your consent you may lose access to certain aspects of
        the INVRS platform. We will explain the impact to you at the time to
        help you with your decision.
      </p>
      <h3 class="heading-tertiary">12. Changes to Our Privacy Policy</h3>
      <p class="legal">
        It is our policy to post any changes we make to our privacy policy on
        this page with a notice that the privacy policy has been updated on the
        Platform home page. If we make material changes to how we treat our
        users' personal information, we will notify you by email to the email
        address specified in your account and through a notice on the Platform
        home page.
      </p>
      <p class="legal">
        We include the date the privacy policy was last revised at the top of
        the page. You are responsible for ensuring we have an up-to-date,
        active, and deliverable email address for you, and for periodically
        visiting our Platform and this privacy policy to check for any changes.
      </p>
      <h3 class="heading-tertiary">
        13. Contact Information and Challenging Compliance
      </h3>
      <p class="legal">
        We welcome your questions, comments, and requests regarding this privacy
        policy and our privacy practices. Please contact us at:
      </p>
      <p class="legal">Verdant Analysis Inc.</p>
      <p class="legal">5700-100 King Street West</p>
      <p class="legal">Toronto, ON M5X 1C7</p>
      <p class="legal">or info@invrs.com</p>
      <p class="legal">
        We have procedures in place to receive and respond to complaints or
        inquiries about our handling of personal information, our compliance
        with this policy, and with applicable privacy laws. To discuss our
        compliance with this policy please contact our Privacy Officer using the
        contact information listed above.
      </p>
    </div>
  </div>
</template>

<style scoped>
.legal {
  margin-bottom: 3.2rem;
  line-height: 1.3;
}

.legal-container {
  padding: 14.4rem 12rem;
}

.heading-tertiary {
  margin-bottom: 1.6rem;
  line-height: 1.2;
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .legal-container {
    padding: 14.4rem 6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .legal-container {
    padding: 12.8rem 2.4rem;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .legal-container {
    padding: 12.8rem 1.2rem;
  }
}
</style>
